import { FormControl, Input, Select, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useState } from 'react';
import { CategoryType } from '../constants/categorytype.constant';
import { staticStyles } from '../constants/style.constant';
import Button from './Button.component';

const TableSelectableCategory = ({ index, name, items, options, onRemove, onAdd, onChange }) => {
    const [currentOption, setCurrentOption] = useState('');
    const [availableOptions, setAvailableOptions] = useState([...options]);

    const handleOptionChange = ({ target: { value } }) => {
        setCurrentOption(value);
    };

    const handleChange = (itemIndex, { target: { id, value }}) => {
        onChange({ catIndex: index, itemIndex: itemIndex, key: id, value, catType: CategoryType.SELECTABLE, itemId: items?.[itemIndex]?.id });
    };

    const handleAdd = () => {
        if (currentOption === '') return;
        const item = availableOptions.find(({ key }) => key === currentOption);
        setCurrentOption('');
        setAvailableOptions(currentOptions => (currentOptions.filter(({ key }) => key !== item.key)));
        onAdd(index, item);
    }

    const handleRemove = (itemIndex, value) => {
        const item = options.find(({ key}) => key === value);
        setAvailableOptions(options => [...options, {...item}]);
        onRemove(index, value, item);
    }

    return (
        <>
            <Thead>
                <Tr>
                    <Th borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color}>{name}</Th>
                    <Th borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color} colSpan={4}>Anzahl</Th>
                </Tr>
            </Thead>
            <Tbody>
                {items.map(({ key: value, name, quantity }, itemIndex) => (
                    <Tr key={`ci-${itemIndex}`}>
                        <Td borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color}>{name}</Td>
                        <Td borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color} colSpan={3}>
                            <FormControl>
                                <Input
                                    type='number' maxWidth='80px'
                                    value={quantity}
                                    min="0"
                                    onChange={(event) => handleChange(itemIndex, event)}
                                    onFocus={(event) => event.target.select()}
                                    onWheel={(event) => event.target.blur()}
                                    style={staticStyles.textInputItem}
                                />
                            </FormControl>
                        </Td>
                        <Td borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color}>
                            {/* <Button 
                                colorScheme='blackAlpha'
                                size='sm'
                                style={{width: '100%'}}
                                variant='solid'
                                onClick={() => handleRemove(itemIndex, value)}
                            >
                                Löschen
                            </Button> */}
                            <Button style={{ width: '100%' }} onClick={() => handleRemove(itemIndex, value)}>
                                Löschen
                            </Button>
                        </Td>
                    </Tr>
                ))}
                <Tr>
                    <Td borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color} colSpan={4}>
                        <Select placeholder='[ausgewähltes Produkt]' size='sm' value={currentOption} onChange={handleOptionChange} style={staticStyles.selectableItem}>
                            {availableOptions.map(({ key, name }, index) => <option key={`${key}-${index}`} value={key}>{name}</option>)}
                        </Select>
                    </Td>
                    <Td borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color}>
                        {/* <Button
                            // colorScheme='blackAlpha'
                            // style={[staticStyles.buttonScheme, {width: '100%'}]}
                            size='sm'
                            variant='solid'
                            onClick={handleAdd}
                        >
                            Hinzufügen
                        </Button> */}
                        <Button style={{ width: '100%' }} onClick={handleAdd}>
                            Hinzufügen
                        </Button>
                    </Td>
                </Tr>
            </Tbody>
        </>
    )
}

export default TableSelectableCategory;