import React from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  Grid,
  theme,
  Image,
  Center,
  Link,
} from '@chakra-ui/react';
import logo from './resources/images/newlogo.png';
import background from './resources/images/bg.jpeg';
import PriceForm from './components/PriceForm.component';
import { staticStyles } from './constants/style.constant';


function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3} style={staticStyles.backgroundStyle}>
          <VStack
            spacing={4}
            align='stretch'
          >
            <Box alignSelf={'center'} style={{padding: '30px'}}>
              <Link href='https://andeer.net'>
                <Image src={logo} alt='Andeer Software' maxWidth={'300px'} />
              </Link>
            </Box>
            {/* <Center> */}
            <Box alignSelf={'center'} style={{
              width: '90%',
              maxWidth: '1100px',
            }}>
              <div style={staticStyles.tableStyle}>
                <PriceForm />
              </div>
            </Box>
            {/* </Center> */}
          </VStack>
          
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
