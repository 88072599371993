import React from 'react';
import {
    TableContainer,
    Table,
    Progress,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';
import TableRegularCategory from './TableRegularCategory.component';
import TableSelectableCategory from './TableSelectableCategory.component';
import TableTotals from './TableTotals.component';
import ClientForm from './ClientForm.component';

/* Hooks */
import { useAsyncLoading } from '../hooks/useAsyncLoading.hook';

/* Cnstants */
import { CategoryType } from '../constants/categorytype.constant';

/* Utils & Helpers */
import { computeItemStats } from '../utils/calc.util';
import { cloneJson } from '../utils/misc.util';
import { fetchAppData } from './PriceForm.helper';

/* Services */
import { createOrder } from '../services/order.service';
import TableCounterCategory from './TableCounterCategory.component';

const EMPTY_FORM = {
    categories: [],
    clientData: {
        firstname: '',
        lastname: '',
        email: '',
        phonenumber: '',
        company: '',
        description: ''
    },
    discounts: {},
};

const RESULT_TYPE = {
    ERROR: "ERR",
    SUCCESS: "SUCC"
};

const RESULT_MESSAGE = {
    ERROR: {
        type: RESULT_TYPE.ERROR,
        title: 'Anfrage nicht übermittelt',
        message: 'Wir konnten Ihre Anfrage nicht senden. Bitte versuchen Sie es erneut oder kontaktieren Sie unseren Administrator unter admin@preise.andeer.net'
    },
    SUCCESS: {
        type: RESULT_TYPE.SUCCESS,
        title: 'Anfrage eingereicht',
        message: 'Vielen Dank für Ihre Anfrage. Unser Team wird sich bald bei Ihnen melden.'
    }
};

const PriceForm = () => {

    const [isLoading, asyncLoad] = useAsyncLoading();
    const [isSubmitting, asyncSubmit] = useAsyncLoading();
    const [submitResult, setSubmitResult] = React.useState({
        show: false,
        type: RESULT_TYPE.SUCCESS,
        title: '',
        message: ''
    });

    const referenceCategoriesRef = React.useRef([])

    const [addonsQuantities, setAddonQuantities] = React.useState([]);
    
    const [formState, setFormState] = React.useState(cloneJson(EMPTY_FORM));

    const handleStateUpdate = ({ key, value }) => {
        setFormState(state => ({
            ...state,
            clientData: {
                ...state.clientData,
                [key]: value
            }
        }));
    };

    const handleRegularCategoryItemChange = ({ catIndex, itemIndex, key, value, catType, itemId }) => {
        if (catType === CategoryType.SELECTABLE) {
            setAddonQuantities(quant => ([
                ...quant.filter(({ id }) => itemId !== id),
                { id: itemId, quantity: Number(value) }
            ]))
        }

        setFormState(state => {
            const newCatItems = state.categories[catIndex].items;
            newCatItems[itemIndex] = {
                ...newCatItems[itemIndex],
                quantity: Number(value)
            };
            const newCategory = {
                ...state.categories[catIndex],
                items: [...newCatItems]
            };
            const newCategories = state.categories;
            newCategories[catIndex] = newCategory;
            return {
                ...state,
                categories: [...newCategories]
            } 
        })
    };

    const handleRemoveItem = (catIndex, itemKey, item) => {
        setAddonQuantities(quant => ([...quant.filter(({ id }) => id !== item?.id)]));
        setFormState(state => {
            const category = state.categories[catIndex];
            const newCategory = {
                ...category,
                items: category.items.filter(({ key }) => key !== itemKey),
            };
            
            state.categories[catIndex] = newCategory;

            return {
                ...state,
                categories: [...state.categories]
            } 
        });
    };

    const handleAddItem = (catIndex, item) => {
        setFormState(state => {
            const newCategoryItems = [...state.categories[catIndex].items, {...item}];
            state.categories[catIndex].items = newCategoryItems;

            setAddonQuantities(quant => ([...quant, { id: item.id, quantity: item.quantity }]));

            return {
                ...state,
                categories: [...state.categories]
            };
        });
    };

    const showResultBox = (resultMessage, time = 7000) => {
        setSubmitResult({
            ...resultMessage,
            show: true
        });
        setTimeout(() => {
            setSubmitResult({
                ...resultMessage,
                show: false,
            })
        }, time);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const counterCategoryQ = addonsQuantities.reduce((total, { quantity }) => quantity + total, 0);

        const order = {
            categories: formState.categories.map(category => {
                const catType = category.type === CategoryType.COUNTER ? CategoryType.SELECTABLE : category.type;
                return {
                    type: catType,
                    name: category.name,
                    items: category.items.map(item => computeItemStats(category.type, item, formState.discounts, { fixedQuantity: counterCategoryQ })),
                }
            }),
            client: {
                ...formState.clientData,
            }
        };

        asyncSubmit(
            createOrder(order)
            .then(() => {
                setFormState(formState => ({
                    ...formState,
                    categories: cloneJson(referenceCategoriesRef.current),
                    clientData: cloneJson(EMPTY_FORM.clientData)
                }));
                setAddonQuantities([]);
                showResultBox(RESULT_MESSAGE.SUCCESS);
            })
            .catch(error => {
                showResultBox(RESULT_MESSAGE.ERROR);
                console.error('Failed to create order'); 
            })
        );
        
    };

    // Effects
    React.useEffect(() => {
        asyncLoad(
            fetchAppData()
            .then(({ mappedDiscounts, mappedCategories }) => {
                setFormState(formState => ({
                    ...formState,
                    categories: cloneJson(mappedCategories),
                    discounts: mappedDiscounts,
                }));
                referenceCategoriesRef.current = cloneJson(mappedCategories);
            })
            .catch(error => console.error(error))
        )
    }, []);

    return (
        <form method='POST' onSubmit={handleSubmit}>
            {isLoading ? (
                <div style={{padding: '10px' }}>
                    <Progress size='sm' isIndeterminate colorScheme="linkedin" />
                </div>
            ) : (
                <>
                    <TableContainer>
                        <Table variant='simple' size='sm'>
                            {formState.categories.map(({ type, name, items, options }, index) => {
                                return (type === CategoryType.REGULAR) ?
                                    <TableRegularCategory
                                        key={`rc-${index}`}
                                        name={name}
                                        items={items}
                                        index={index}
                                        discountMap={formState.discounts}
                                        onChange={handleRegularCategoryItemChange}
                                    /> :
                                    (type === CategoryType.SELECTABLE ? 
                                        <TableSelectableCategory
                                            key={`sc-${index}`}
                                            name={name}
                                            items={items}
                                            index={index}
                                            options={options}
                                            onAdd={handleAddItem}
                                            onRemove={handleRemoveItem}
                                            onChange={handleRegularCategoryItemChange}
                                        /> :
                                        <TableCounterCategory
                                            key={`rc-${index}`}
                                            name={name}
                                            items={items}
                                            index={index}
                                            quantity={addonsQuantities?.reduce((total, { quantity }) => total + quantity, 0) || 0}
                                            discountMap={formState.discounts}
                                        />
                                    );
                            })}
                            <TableTotals categories={formState.categories} discountMap={formState.discounts} />
                        </Table>
                    </TableContainer>
                    {(submitResult?.show === true) ? (
                            <Alert
                                status={submitResult?.type === RESULT_TYPE.SUCCESS ? 'success' : 'error'}
                                variant='subtle'
                                flexDirection='column'
                                alignItems='center'
                                justifyContent='center'
                                textAlign='center'
                                height='200px'
                            >
                                <AlertIcon boxSize='40px' mr={0} />
                                <AlertTitle mt={4} mb={1} fontSize='lg'>
                                    {submitResult?.title}
                                </AlertTitle>
                                <AlertDescription maxWidth='sm'>
                                    {submitResult?.message}
                                </AlertDescription>
                            </Alert>
                        ) : (
                            <ClientForm
                                {...formState.clientData}
                                onChange={handleStateUpdate}
                                onSubmit={handleSubmit}
                                isSubmitting={isSubmitting}
                            />
                        )
                    }
                </>
            )}
            
            
        </form>
    )
};

export default PriceForm;