export const BackendAPI = {
    // getPathForEndpoint: (endpoint) => `./backend/public/api/${endpoint}`,
    getPathForEndpoint: (endpoint) => `https://preise.andeer.net/backend/public/api/${endpoint}`,
    endpoint: {
        order: {
            submit: 'order',
        },
        category: {
            list: 'category',
        },
        discount: {
            list: 'discount',
        },
        module: {
            list: 'module',
        },
    }
};