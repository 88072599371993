import {
    // Button,
    Flex,
    FormControl,
    Input,
    Stack,
    Textarea,
} from '@chakra-ui/react';
import React from 'react';
import Button from './Button.component';
import { staticStyles } from '../constants/style.constant';

const ClientForm = ({
    name,
    surname,
    email,
    phonenumber,
    company,
    description,
    onChange,
    onSubmit,
    isSubmitting = false
}) => {
    const handleChange = ({ target: { id, value }}) => {
        onChange({ key: id, value });
    };
    
    return (
        <Flex style={{padding: '15px'}}>
            <Stack flex='1' style={{paddingRight: '5px'}}>
                <FormControl>
                    <Input
                        id='firstname'
                        type='text'
                        placeholder='Name *'
                        size='sm'
                        value={name}
                        onChange={handleChange}
                        style={staticStyles.textInputItem}
                        required
                    />
                </FormControl>
                <FormControl>
                    <Input
                        id='lastname'
                        type='text'
                        placeholder='Vorname *'
                        size='sm'
                        value={surname}
                        onChange={handleChange}
                        style={staticStyles.textInputItem}
                        required
                    />
                </FormControl>
                <FormControl>
                    <Input
                        id='email'
                        type='email'
                        placeholder='Mail *'
                        size='sm'
                        value={email}
                        onChange={handleChange}
                        style={staticStyles.textInputItem}
                        required
                    />
                </FormControl>
                <FormControl>
                    <Input
                        id='phonenumber'
                        type='text'
                        placeholder='Telefon *'
                        size='sm'
                        value={phonenumber}
                        onChange={handleChange}
                        style={staticStyles.textInputItem}
                        required
                    />
                </FormControl>
            </Stack>
            <Stack flex='1' style={{paddingLeft: '5px'}}>
                <FormControl>
                    <Input
                        id='company'
                        type='text'
                        placeholder='Firma *'
                        size='sm'
                        value={company}
                        onChange={handleChange}
                        style={staticStyles.textInputItem}
                        required
                    />
                </FormControl>
                <FormControl>
                    <Textarea
                        id='description'
                        placeholder='Bemerkungen'
                        size='sm'
                        style={{...staticStyles.textInputItem, minHeight: '75px'}}
                        value={description}
                        onChange={handleChange}
                    />
                </FormControl>
                {/* <Button
                    isLoading={isSubmitting}
                    loadingText='Einreichen'
                    colorScheme='blackAlpha'
                    size='sm'
                    variant='solid'
                    type='submit'
                >
                    Anfrage Sender
                </Button> */}
                <Button
                    isLoading={isSubmitting}
                    loadingText='Einreichen'
                    type='submit'
                >
                    Anfrage Sender
                </Button>
            </Stack>
        </Flex>
    );
};

export default ClientForm;