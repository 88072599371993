import { BackendAPI } from "../constants/api.constant";
import { CategoryType } from "../constants/categorytype.constant";
import api from "../utils/api.util";

export const fetchCategories = async () => {
    const response = await api.get(BackendAPI.getPathForEndpoint(BackendAPI.endpoint.category.list));
    const categories = response.data || [];

    // Fixed Counter category
    const uCategories = categories.map(cat => cat.id === 7 ? {...cat, type: CategoryType.COUNTER} : cat);

    return [...uCategories];
};