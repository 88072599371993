import { Box } from "@chakra-ui/react"
import { staticStyles } from "../constants/style.constant"

const Button = ({
    children,
    style,
    onClick,
    ...rest
}) => {

    return (
        <Box
            as='button'
            height={staticStyles.buttonScheme._height}
            paddingX={'5'}
            lineHeight='1.2'
            transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
            alignItems={'center'}
            justifyContent={'center'}
            borderRadius='5px'
            fontSize='14px'
            fontWeight='semibold'
            bg={staticStyles.buttonScheme._bgColor}
            color={staticStyles.buttonScheme._color}
            _hover={{ opacity: 0.8 }}
            _active={{
                // bg: '#dddfe2',
                opacity: 0.9,
                transform: 'scale(0.98)',
                // borderColor: '#bec3c9',
            }}
            _focus={{
                boxShadow:
                '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
            }}
            style={style}
            {...rest}
            onClick={(event) => {
                onClick()
                event.preventDefault()
            }}
        >
            {children}
        </Box>
    )
}

export default Button