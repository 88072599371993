export const staticStyles = {
    backgroundStyle: {
        // backgroundImage: `url(${background})`,
        // backgroundColor: '#111111', 
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover'
        backgroundColor: '#ffffff'
    },
    tableStyle: {
        // backgroundColor: '#fafafa',
        backgroundColor: '#C3EFFF',
        padding: '10px',
        borderRadius: '10px',
        opacity: 0.9,
    },
    tableTotal: {
        thead: {
            backgroundColor: '#013B56', //'rgba(0, 0, 0, 0.66)',
            height: '50px'
        },
        td: {
            _color: '#fff',
        }
    },
    buttonScheme: {
        _bgColor: '#013B56',
        _color: '#ffffff',
        _height: '32px'
    },
    selectableItem: {
        backgroundColor: '#fff',
        opacity: 0.8
    },
    tableText: {
        _color: '#013B56'
    },
    tableCell: {
        _discountBgColor: '#85DFFF',
        _notNullQuantityColor: '#A8E8FF',
        _borderColor: '#013B56'
    },
    textInputItem: {
        backgroundColor: '#fff',
        opacity: 0.8,
        // color: '#013B56'
    }
}

