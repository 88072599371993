import {
    FormControl,
    Input,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react';
import React from 'react';
import { CategoryType } from '../constants/categorytype.constant';
import { computeItemStats } from '../utils/calc.util';
import { staticStyles } from '../constants/style.constant';

const TableRegularCategory = ({ index, name, items, discountMap, onChange }) => {
    const handleChange = (itemIndex, { target: { id, value }}) => {
        onChange({ catIndex: index, itemIndex: itemIndex, key: id, value });
    };

    return (
        <>
            <Thead>
                <Tr>
                    <Th borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color}>{name}</Th>
                    <Th borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color}>Anzahl</Th>
                    <Th borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color} colSpan={2}  textAlign={'right'}>Monatliche Kosten</Th>
                    <Th borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color} textAlign={'right'}>Rabatt</Th>
                    {/* <Th colSpan={2}>Jährliche Kosten</Th>
                    <Th>Rabatt</Th> */}
                </Tr>
            </Thead>
            <Tbody>
                {items.map((item, index) => {
                    // { name, quantity, price, yearPrice, discountId }
                    const {
                        name,
                        quantity,
                        price,
                        totalPrice,
                        discount,
                        yearPrice,
                        totalYearPrice,
                        yearDiscount
                    } = computeItemStats(CategoryType.REGULAR, item, discountMap);
                    
                    return (
                        <Tr key={`ri-${index}`} bg={ quantity > 0 ? staticStyles.tableCell._notNullQuantityColor : null }>
                            <Td borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color}>{name}</Td>
                            <Td borderColor={staticStyles.tableCell._borderColor}>
                                <FormControl>
                                    <Input
                                        type='number'
                                        maxWidth='80px'
                                        value={quantity}
                                        onChange={(event) => handleChange(index, event)}
                                        onFocus={(event) => event.target.select()}
                                        onWheel={(event) => event.target.blur()}
                                        style={staticStyles.textInputItem}
                                    />
                                </FormControl>
                            </Td>
                            <Td borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color} textAlign={'right'}>{price?.toFixed(2)}</Td>
                            <Td borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color} textAlign={'right'}>{totalPrice?.toFixed(2)}</Td>
                            <Td borderColor={staticStyles.tableCell._borderColor} color={staticStyles.tableText._color} textAlign={'right'} bg={discount > 0 ? staticStyles.tableCell._discountBgColor : null }>{discount}%</Td>
                            {/* <Td>{yearPrice?.toFixed(2)}</Td>
                            <Td>{totalYearPrice?.toFixed(2)}</Td>
                            <Td>{yearDiscount?.toFixed(2)}%</Td> */}
                        </Tr>
                    );
                })}
            </Tbody>
        </>
    );
};

export default TableRegularCategory;