import { Text, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import { CategoryType } from '../constants/categorytype.constant';
import { computeItemStats } from '../utils/calc.util';
import { staticStyles } from '../constants/style.constant';

const TableTotals = ({ categories, discountMap }) => {
    const { totalPrice, totalYearPrice } = categories.reduce(({ totalPrice, totalYearPrice }, { type, items }) => {
        if (type === CategoryType.SELECTABLE) return { totalPrice, totalYearPrice };
        const { totalPrice: iTotalPrice, totalYearPrice: iTotalYearPrice } = items.reduce(
            ({ totalPrice, totalYearPrice }, item) => {
                const { totalPrice: cTotalPrice, totalYearPrice: cTotalYearPrice } = computeItemStats(type, item, discountMap);
                return {
                    totalPrice: totalPrice + cTotalPrice,
                    totalYearPrice: totalYearPrice + cTotalYearPrice
                }
            },
            { totalPrice: 0, totalYearPrice: 0 }
        );

        return {
            totalPrice: totalPrice + iTotalPrice,
            totalYearPrice: totalYearPrice + iTotalYearPrice,
        }
    }, { totalPrice: 0, totalYearPrice: 0 });

    return (
        <Thead style={staticStyles.tableTotal.thead}>
            <Tr>
                <Th colSpan={2} color={staticStyles.tableTotal.td._color} textAlign='left'>
                    <Text fontSize={14}>Total (exkl. MWSt)</Text>
                </Th>
                <Th colSpan={3} textAlign='right' color={staticStyles.tableTotal.td._color}>
                    <Text fontSize={14}>Monatliche Kosten CHF {totalPrice?.toFixed(2)}</Text>
                </Th>
                {/* <Th colSpan={2} textAlign='right' color={'#fff'}>pro Jahr CHF {totalYearPrice?.toFixed(2)}</Th> */}
            </Tr>
        </Thead>
    );
};

export default TableTotals;